import remark from 'remark'
import remarkHTML from 'remark-html'

export const parseDocument = data => {
	let { creator, title, column, en_title, en_column, description, en_description, document_images, year, type, related_creator } = data.markdownRemark.frontmatter
	const { slug, enSlug } = data.markdownRemark.fields
	en_column = data.markdownRemark.fields.frontmattermd.en_column && data.markdownRemark.fields.frontmattermd.en_column.html
	column = data.markdownRemark.fields.frontmattermd.column && data.markdownRemark.fields.frontmattermd.column.html
	en_description = data.markdownRemark.fields.frontmattermd.en_description && data.markdownRemark.fields.frontmattermd.en_description.html
	description = data.markdownRemark.fields.frontmattermd.description && data.markdownRemark.fields.frontmattermd.description.html

	return {
		title, en_title, column, en_column, description, en_description, creator, images: document_images, year, type, slug, enSlug, id: data.markdownRemark.id, related_creator,
	}

}

export const parseEvents = (data) => {
	return data.allMarkdownRemark.edges.map((_event) => ({
		..._event.node.frontmatter,
		slug: _event.node.fields.slug,
		description: _event.node.fields.frontmattermd.description && _event.node.fields.frontmattermd.description.html,
		id: _event.node.id,
	}))
}

export const parseStaticPage = (data) => {
	let { author, title, menu, attachments, column, en_title, en_body, en_column } = data.markdownRemark.frontmatter
	const { slug, enSlug } = data.markdownRemark.fields
	const { html } = data.markdownRemark
	en_body = data.markdownRemark.fields.frontmattermd.en_body && data.markdownRemark.fields.frontmattermd.en_body.html
	en_column = data.markdownRemark.fields.frontmattermd.en_column && data.markdownRemark.fields.frontmattermd.en_column.html
	column = data.markdownRemark.fields.frontmattermd.column && data.markdownRemark.fields.frontmattermd.column.html
	return { author, title, html, menu, attachments, column, en_title, en_body, en_column, slug, enSlug }
}

export const parseRepositoryPage = data => {
	const documents = data.allMarkdownRemark.edges.map(edge => {
		return {
			markdownRemark: edge.node
		}
	})
	const parsedDocuments = documents.map(parseDocument)
	return {
		documents: parsedDocuments
	}
}

export const parseAuthorPage = (data) => {
	const authors = data.allMarkdownRemark.edges.map((_event) => ({
		..._event.node.frontmatter,
		slug: _event.node.fields.slug,
		enSlug: _event.node.fields.enSlug,
	}))
	return {
		authors,
	}
}

export const parseCategoryPage = (data) => {
	const commonData = parseStaticPage(data)
	const { category } = data.markdownRemark.frontmatter
	const events = parseEvents(data)
	return {
		...commonData,
		category,
		events,
	}
}

export const toHTML = (value) => remark().use(remarkHTML).processSync(value).toString()

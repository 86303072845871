import * as React from 'react'
import { css } from '@emotion/core'

const additionalContentGridStyle = (theme) => css`
	${theme.grid.default};
	max-width: 100%;

	& > * {
		grid-column: 2 / 3;
	}

	${theme.breakpoints.tablet} {
		& > * {
			grid-column: 1 / 4;
		}
	}
`

const ContentGrid = ({ ...props }) => <div css={additionalContentGridStyle} {...props} />
export default ContentGrid
